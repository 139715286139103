import React from "react"

import Index from "../components/layout"
import Cookie from "../components/cookiepolicy";

const CookiePolicy = () => {

    return (
        <Index title="Cookie Policy">
            <Cookie/>
        </Index>
    )
}

export default CookiePolicy

import React, {useEffect, useRef} from "react"
import {gsap} from "gsap";
import Logo from '../images/cookie.svg'

const Cookie = () => {

    let cookieImage = useRef(null);
    let cookieTitle = useRef(null);
    let cookieText = useRef(null);

    useEffect(() => {
        gsap.from(cookieImage, 0.8, {
            opacity: 0,
            scale: 1.6,
            ease: "Power2.easeInOut",
            delay: -0.2
        });
        gsap.from(cookieTitle, 0.4, {
            opacity: 0,
            y:32,
            delay:0.1
        });
        gsap.from(cookieText, 0.4, {
            opacity: 0,
            y: 64,
            delay:0.1
        });
    }, [cookieImage, cookieTitle, cookieText]);

    return (
        <section className="cookie-policy ">
                <div className="img-wrapper">
                    <img ref={el => (cookieImage = el)} src={Logo} alt="" />
                </div>
                <div className="text-wrapper">
                    <h1 ref={el => (cookieTitle = el)}>Cookie szabályzat:</h1>
                    <p ref={el => (cookieText = el)}>A szabályzat a <a href="/">rockaholik.com</a> oldalon használt cookie-kra vonatkozik (továbbiakban „honlap”).
                        <br/>Kérjük, hogy a honlap használata előtt olvassa el figyelmesen!
                        <br/><br/><a href="../media/Cookie-szabalyzat.pdf" target="_blank" rel="noopener noreferrer">Elolvasom a teljes dokumentumot (.pdf)</a>
                    </p>
                </div>
        </section>
    );
}

export default Cookie
